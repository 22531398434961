var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "inner", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { staticClass: "overview-filters align-items-start" },
        [
          _c("filter-set", {
            attrs: {
              name: _setup.FilterDataName.PowerBi,
              filters: _setup.filters,
            },
            on: {
              "filter-search": _setup.handleFilterSearch,
              "filters-changed": _setup.handleFiltersChanged,
            },
          }),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                dark: "",
                to: {
                  name: "PowerBIReportDetails",
                  params: {
                    partyReference: _setup.props.partyReference.toString(),
                  },
                },
              },
            },
            [
              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                _vm._v("mdi-plus"),
              ]),
              _vm._v(" Rapport toevoegen "),
            ],
            1
          ),
        ],
        1
      ),
      _c(_setup.PartyPbiGrid, {
        attrs: {
          partyReference: _vm.partyReference,
          filterData: _setup.filterData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }