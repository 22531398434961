var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "header",
        { staticClass: "app-welcome-message", attrs: { cols: "auto" } },
        [
          _c(
            "h2",
            [
              _setup.party.id
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        to: {
                          name: "PartyDetails",
                          params: {
                            partyReference: _setup.party.reference.toString(),
                          },
                        },
                        icon: "",
                        small: "",
                        plain: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-pencil "),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" Overzicht "),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "masonry",
                { attrs: { cols: { default: 2, 1264: 1 }, gutter: 12 } },
                [
                  _c(
                    "v-container",
                    {
                      staticClass: "boxed",
                      class: { noBorders: false },
                      attrs: { fluid: "" },
                    },
                    [
                      _c("header", [_vm._v("Geldigheid")]),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Geldig van")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _setup.formatDateUtcD(_setup.party.valFrom)
                                )
                            ),
                          ]),
                          _c("v-col", [_c("label", [_vm._v("Geldig tot")])]),
                          _c("v-col", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _setup.formatDateUtcD(_setup.party.valTill)
                                )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _setup.party.detailCnt > 1
                        ? _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _c("label", [_vm._v("Versies")]),
                              ]),
                              _c("v-col", [
                                _vm._v(" " + _vm._s(_setup.party.detailCnt)),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _setup.party.detailCnt > 1
                        ? _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _c("label", [_vm._v("Begindatum")]),
                              ]),
                              _c("v-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _setup.formatDateUtcD(
                                        _setup.party.dateFrom
                                      )
                                    )
                                ),
                              ]),
                              _c("v-col", [_c("label", [_vm._v("Einddatum")])]),
                              _c("v-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _setup.formatDateUtcD(
                                        _setup.party.dateTill
                                      )
                                    )
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { staticClass: "boxed", class: { noBorders: false } },
                    [
                      _c("header", [_vm._v("Aanpassing")]),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Door")]),
                          ]),
                          _c("v-col", [
                            _vm._v(" " + _vm._s(_setup.party.modBy) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Omschrijving")]),
                          ]),
                          _c("v-col", [
                            _vm._v(" " + _vm._s(_setup.party.modDescr) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Datum")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _setup.formatDateUtcDT(_setup.party.modDat)
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "masonry",
                { attrs: { cols: { default: 3, 768: 2, 512: 1 }, gutter: 12 } },
                [
                  _c(
                    "v-container",
                    { staticClass: "boxed" },
                    [
                      _c("header", [_vm._v("Algemene gegevens")]),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c("img", {
                              staticStyle: {
                                "max-width": "200px",
                                "max-height": "200px",
                              },
                              attrs: { src: _setup.logoName },
                            }),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Referentie (INNAX-ID)")]),
                          ]),
                          _c("v-col", [
                            _vm._v(" " + _vm._s(_setup.party.reference) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Parent")]),
                          ]),
                          _c("v-col", [
                            _vm._v(" " + _vm._s(_setup.party.parName) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Naam")]),
                          ]),
                          _c("v-col", [
                            _vm._v(" " + _vm._s(_setup.party.name) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Omschrijving")]),
                          ]),
                          _c("v-col", [
                            _vm._v(" " + _vm._s(_setup.party.descr) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Type")]),
                          ]),
                          _c("v-col", [
                            _vm._v(" " + _vm._s(_setup.party.typeDescr) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { staticClass: "boxed" },
                    [
                      _c("header", [_vm._v("Energieprijzen")]),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Elektriciteit")]),
                          ]),
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _setup.party.priceElec != null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _setup.party.priceElec,
                                        "€ ",
                                        4,
                                        {
                                          thousandsSeparator: " ",
                                          decimalSeparator: ".",
                                        }
                                      )
                                    ) + " /kWh"
                                  ),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Gas")]),
                          ]),
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _setup.party.priceGas != null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _setup.party.priceGas,
                                        "€ ",
                                        4,
                                        {
                                          thousandsSeparator: " ",
                                          decimalSeparator: ".",
                                        }
                                      )
                                    ) + " /m³"
                                  ),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Biomassa")]),
                          ]),
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _setup.party.priceBio != null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _setup.party.priceBio,
                                        "€ ",
                                        4,
                                        {
                                          thousandsSeparator: " ",
                                          decimalSeparator: ".",
                                        }
                                      )
                                    ) + " /m³"
                                  ),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Warmte")]),
                          ]),
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _setup.party.priceHeat != null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _setup.party.priceHeat,
                                        "€ ",
                                        4,
                                        {
                                          thousandsSeparator: " ",
                                          decimalSeparator: ".",
                                        }
                                      )
                                    ) + " /GJ"
                                  ),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Koude")]),
                          ]),
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _setup.party.priceCold != null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _setup.party.priceCold,
                                        "€ ",
                                        4,
                                        {
                                          thousandsSeparator: " ",
                                          decimalSeparator: ".",
                                        }
                                      )
                                    ) + " /GJ"
                                  ),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { staticClass: "boxed" },
                    [
                      _c("header", [_vm._v("Verduurzamingsradar")]),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "6" } }, [
                            _c("label", [_vm._v("Strategie")]),
                          ]),
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _setup.party.radarStrategy != null
                              ? _c("span", [
                                  _vm._v(_vm._s(_setup.party.radarStrategy)),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "6" } }, [
                            _c("label", [_vm._v("Inzicht")]),
                          ]),
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _setup.party.radarInsight != null
                              ? _c("span", [
                                  _vm._v(_vm._s(_setup.party.radarInsight)),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "6" } }, [
                            _c("label", [_vm._v("Gedeelde waarden")]),
                          ]),
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _setup.party.radarSharedValues != null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_setup.party.radarSharedValues)
                                  ),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "6" } }, [
                            _c("label", [_vm._v("Status")]),
                          ]),
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _setup.party.radarStatus != null
                              ? _c("span", [
                                  _vm._v(_vm._s(_setup.party.radarStatus)),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "6" } }, [
                            _c("label", [_vm._v("Slagkracht")]),
                          ]),
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _setup.party.radarStrikingPower != null
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_setup.party.radarStrikingPower)
                                  ),
                                ])
                              : _c("span", [_vm._v("-")]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-container", { staticClass: "boxed d-none" }),
                  _c(
                    "v-container",
                    { staticClass: "boxed" },
                    [
                      _c("header", [_vm._v("Contacten")]),
                      _vm._l(_setup.contactList, function (item, index) {
                        return _c(
                          "v-row",
                          { key: index },
                          [
                            _c("v-col", { attrs: { cols: "4" } }, [
                              _vm._v(_vm._s(item.partyRoleDescription)),
                            ]),
                            _c("v-col", [_vm._v(_vm._s(item.userDescription))]),
                            item.valid == false
                              ? _c("v-col", [_vm._v(" (niet actief) ")])
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "v-container",
                    { staticClass: "boxed" },
                    [
                      _c("header", [_vm._v("Herkomst")]),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Bron")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " + _vm._s(_setup.party.sourceTypeDescr) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "4" } }, [
                            _c("label", [_vm._v("Bron referentie")]),
                          ]),
                          _c("v-col", [
                            _vm._v(
                              " " + _vm._s(_setup.party.sourceReference) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "overview-wrapper" },
        [
          _c("v-col", { attrs: { cols: "2" } }, [
            _c("header", { staticClass: "app-welcome-message" }, [
              _c("h2", [_vm._v("Locaties")]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "overview-wrapper" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _setup.party.reference
                ? _c(_setup.LocationOverview, {
                    attrs: {
                      partyReference: _setup.party.reference,
                      allowMutationAddress: false,
                      allowConnectAddressToParty: true,
                      explicitPageSize: 10,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "overview-wrapper" },
        [
          _c("v-col", { attrs: { cols: "2" } }, [
            _c("header", { staticClass: "app-welcome-message" }, [
              _c("h2", [_vm._v("PowerBI koppelingen")]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "overview-wrapper" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _setup.party.reference
                ? _c(_setup.PartyPbiOverview, {
                    attrs: {
                      partyReference: _setup.party.reference,
                      allowMutationAddress: false,
                      allowConnectAddressToParty: true,
                      explicitPageSize: 10,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }